import { Routes, Route } from 'react-router-dom';
import Usuarios from './usuarios/usuarios';
import Avisos from './avisos/avisos';
import Batismo from './batismo/batismo';



import Barra from './componentes/barra';

function Restrito() {
    return (
        <div>
            <Barra/>
            
            <Routes>
              <Route path="usuarios" element={<Usuarios />} />
              <Route path="avisos" element={<Avisos tipo="aviso" header="Avisos" />} />
              <Route path="encontros" element={<Avisos tipo="encontro" header="Encontros" />} />
              <Route path="batismo" element={<Batismo/>}/>
            </Routes>
        </div>
            );
}


export default Restrito;