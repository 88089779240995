import Container from 'react-bootstrap/Container';
import axios from 'axios';
import {useState, useEffect} from 'react';

import Carousel from 'react-bootstrap/Carousel';


import './home.css';

function Encontros() {
    
    const [registro, setRegistro] = useState([]);

    useEffect(()=>{
        async function getData (){
            //let url = "ttp://localhost:8888/saopedro/src/site/pagina/restrito/avisos/controles/get.php?tipo=encontro";
            let URL = "http://dev.pedrodomar.com/saopedro/src/site/pagina/restrito/avisos/controles/get.php?tipo=encontro";
            
            //108.179.252.60 - host
            //172.20.10.2 - local
            try{
                const result = await axios.get(URL);
                setRegistro(result.data);
            } catch (error){
                alert(JSON.stringify(error));
            };
        };
        getData();
        
    },[]);
    return (
    <div className='encontros-grupos py-3 ps-3 d-flex nowrap'>
        <Carousel fade className='encontros-carrossel mx-auto'>

        {registro.map((item,index)=>(
      <Carousel.Item key={index}>
        <img src={require(`../../restrito/avisos/img/${item.arquivo}`)} width={380} alt='' />

      </Carousel.Item>
      ))}


    </Carousel>

    <Container className="d-flex align-items-center justify-content-center mx-auto">
    <h1 className="adlam">Nossos Encontros</h1>
            </Container>

    
    </div>
            );
}


export default Encontros;