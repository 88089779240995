import Lista from './lista';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import {useState} from 'react';

function Avisos(props) {
    const STATUS_IDLE = 0;
    const STATUS_UPLOADING = 1;
    const [files, setFiles] = useState([]);
    const [status, setStatus] = useState(STATUS_IDLE);
    
    const idUsuario = sessionStorage.getItem("idUsuario");
    
const uploadFiles = async (data) => {
    setStatus(STATUS_UPLOADING);
    //console.log(data);
    let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/avisos/controles/add.php";
    try {
        const res = await fetch(url, {
            method: 'POST',
            body: data
        });
        const result = await res.json();
        //alert("Adicionado!");
        console.log(result);
        
    } catch (err) {
        console.log("Erro:", err);
    } finally {
        setStatus(STATUS_IDLE);
    }
};

const handleUploadClick = () => {
    if (files.length) {
        const data = packFiles(files);
        const tipoData = {tipo: props.tipo};
        const id = {id: idUsuario};
        data.append("tipo", JSON.stringify(tipoData));
        data.append("id", JSON.stringify(id));
        //Object.assign(data, JSON.stringify({tipo: props.tipo}));
        uploadFiles(data);
    }
    setFiles([]);
    document.getElementById("input_img").value = "";
};

    
    const packFiles = (files)=>{
        const data = new FormData();
        [...files].forEach((file, i) => {
            data.append(`file-${i}`, file, file.name);
        });
        return data;
    };
    

    
    
    const renderFileList = () => (
            <ol>
            {[...files].map((f, i) => (
                    <li key={i}>{f.name} - {f.type}</li>)
                    )}
            </ol>
                    );
            
    const renderButtonStatus = () => (
            (status === STATUS_IDLE) ? 
            'Adicionar' : 'Selecione'
            );
    

    return (
            <div>
    <Container>
    <h2 className="adlam fonte-azul-5">{props.header}</h2>
    </Container>
            <Lista/>
            <Form className="addImagem d-flex justify-content-center mx-auto mt-3 mb-5">
                <Form.Control type="file" name="image" id="input_img" multiple onChange={(e)=> setFiles(e.target.files)}/>{renderFileList()}
                
                <Button onClick={handleUploadClick} disabled={status === STATUS_UPLOADING}>{renderButtonStatus()}</Button>
            </Form>
            </div>
            );
}


export default Avisos;