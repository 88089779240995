import {Link} from 'react-router-dom';

function BtnUsuarios() {
    
    const isAdmin = sessionStorage.getItem("admin");

    if(isAdmin==="true"){
        return (
            <Link to="/restrito/usuarios" className="nav-link">
                Usuários
            </Link>
            );
    
    }else{
        return ;
        
    }
}


export default BtnUsuarios;