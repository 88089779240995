import React from 'react';
import Card from 'react-bootstrap/Card';
import Elemento from '../../../img/elemento_1.png';

function Lista({ lista }) {
  const mes = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];

  return (
    <div>
      {lista.map((value, index) => (
        <div key={index}>
          <Card className="batismo_card mx-auto">
            <Card.Img src={Elemento}/>
            <Card.ImgOverlay>
            <Card.Body className="fonte-azul-5 ms-3">
            <Card.Title className="adlam text-center">
              {mes[value.periodo.mes]}/{value.periodo.ano}
            </Card.Title>

              <Card.Title>Inscrições</Card.Title>
              <Card.Text>{value.info.pre}<br/>{value.info.inscricao}</Card.Text>

              <Card.Title>Encontro de Pais e Padrinhos</Card.Title>
              <Card.Text>{value.info.encontro}</Card.Text>

              <Card.Title>Batismo</Card.Title>
              <Card.Text>{value.info.batismo}</Card.Text>
            </Card.Body>
            </Card.ImgOverlay>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default Lista;
