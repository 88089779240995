import {Link, useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';
import HandleSession from '../../login/handleSession';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Container from 'react-bootstrap/Container';

import Logo from '../../../img/logo-horizontal-dourado.png';
import BtnUsuarios from './btnUsuarios';

function Barra() {
    const [usuario, setUsuario] = useState(HandleSession.getName());
    const navigate = useNavigate();
    const [email, setEmail] = useState(sessionStorage.getItem("email"));
    
    const [idUsuario, setIdUsuario] = useState(sessionStorage.getItem("idUsuario"));
    
    const trocaSenha = HandleSession.getSenha();
    const Sair = () =>{
        setIdUsuario(null);
        setEmail(null);
        HandleSession.setName(null);
        setUsuario(null);
        navigate("/login");
    };
    
    useEffect(()=>{
        if(!email){
            Sair();
        }
        if(trocaSenha){
            navigate("trocaSenha");
        }
    });

    return (
<Navbar expand="lg" variant="saopedro">
                <Container>
                    <Navbar.Brand href="#">
                    <Link to="/restrito">
                        <img 
                            src={Logo}
                            height="90"
                            className="d-inline-block align-top navbar-logo"
                            alt=''
                            />
                            </Link>
                    </Navbar.Brand>        
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                      
                        <Link to="/restrito/fotos" className="nav-link">    
                            Galeria de Fotos
                        </Link>
                        
                        <Link to="/restrito/avisos" state={{tipo : "aviso"}}  className="nav-link">
                            Avisos
                        </Link>
                        
                        <Link to="/restrito/encontros" state={{tipo : "encontro"}} className="nav-link">
                            Encontros
                        </Link>
                        
                        <Link to="/restrito/batismo" className="nav-link">
                            Batismo
                        </Link>                        

                        <Link to="/restrito/config" className="nav-link">
                            Configurações
                        </Link>

                        
                        <BtnUsuarios/>
                        <Link to="/" className="nav-link" onClick={Sair}>Sair</Link>
                      </Nav>
                      <span className="erro">{usuario} - {email} - {idUsuario}</span>
                    </Navbar.Collapse>
                  </Container>
            </Navbar>
            );

}


export default Barra;