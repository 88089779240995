import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import Button from  'react-bootstrap/Button';
import React, {useEffect, useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import HandleSession from '../../login/handleSession';
import axios from 'axios';
import './batismo.css';
import Lista from './lista';

function Batismo() {
    
    const idUsuario = sessionStorage.getItem("idUsuario");

    
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
        
    const resetData = {
        idUsuario:  idUsuario,
        ano:        currentYear,
        mes:        currentMonth,
        pre:        '',
        inscricao:  '',
        encontro:   '',
        batismo:    ''
    };
    
    const [data, setData] = useState(resetData);
    
    
    
    const handleChange = (e) => {
        setData({...data,[e.target.name]:e.target.value});  
    };
    

    
    
    const handleSubmit = (e)=>{
        e.preventDefault();
        let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/batismo/controles/add.php";
        
        axios
                .post(url, data)
                .then(
                    (res)=>{
                        setData(resetData);
                        alert("Salvo com sucesso!");
                        window.location.reload(false);
                    }
                )
                .catch(
                    (erro)=>{
                        if(erro.response.data.status === 401){
                            alert(erro.response.data.message);
                        }else{
                            alert(erro.message);
                        }
            });
        };

    return (
            <div>
                <Container>
                    <h2 className="adlam fonte-azul-5">Batismo</h2>
                </Container>   
                
                <Form className="form_batismo mx-auto" >
                    
    <Row>
        <InputGroup className='mb-3'>
            <InputGroup.Text>Período</InputGroup.Text>

            <Form.Select type="number" name="ano" onChange={handleChange}>
                <option value={currentYear}>{currentYear}</option>
                <option value={currentYear+1}>{currentYear+1}</option>
                <option value={currentYear+2}>{currentYear+2}</option>
            </Form.Select>
   
            <Form.Select type="number" autoFocus name="mes" onChange={handleChange}>
                <option value="0" >Janeiro</option>
                <option value="1" >Fevereiro</option>
                <option value="2" >Março</option>
                <option value="3" >Abril</option>
                <option value="4" >Maio</option>
                <option value="5" >Junho</option>
                <option value="6" >Julho</option>
                <option value="7" >Agosto</option>
                <option value="8" >Setembro</option>
                <option value="9" >Outubro</option>
                <option value="10">Novembro</option>
                <option value="11">Dezembro</option>
            </Form.Select>
    
        </InputGroup>
    </Row>
    
    <Row>
        
    
            <InputGroup className="mb-3" >
                <InputGroup.Text>Pré-Inscrição</InputGroup.Text>
                <Form.Control 
                    type="date" 
                    value={data.pre}  
                    onChange={handleChange} 
                    name="pre" 
                    className='text-end' 
                    />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text>Inscrição</InputGroup.Text>
                <Form.Control 
                    type="date"
                    value={data.inscricao}
                    onChange={handleChange}
                    name="inscricao"
                    className="text-end"
                    />
            </InputGroup>
            

        
            <InputGroup className="mb-3">
                <InputGroup.Text>EPP</InputGroup.Text>
                <Form.Control 
                type="date"
                value={data.encontro}
                onChange={handleChange}
                name="encontro"
                className="text-end"
                />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text>Batismo</InputGroup.Text>
                <Form.Control 
                    type="date"
                    value={data.batismo}
                    onChange={handleChange}
                    name="batismo"
                    className="text-end"
                    />
            </InputGroup>
    </Row>    
  
    <Row>

        <Form.Group className="d-flex justify-content-end mb-3">
            <Button onClick={handleSubmit}>Incluir</Button>
        </Form.Group>
            
            
    </Row>
    
            </Form>
            
    <Lista/>
        </div>
            );
}


export default Batismo;