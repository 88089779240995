import {Link} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import './elementos.css';

import Logo from '../img/logo-horizontal-dourado.png';

function Barra() {

    return (
            <Navbar expand="lg" variant="saopedro">
                <Container>
                    <Navbar.Brand href="#">
                    <Link to="/">
                        <img 
                            src={Logo}
                            height="90"
                            className="d-inline-block align-top navbar-logo"
                            alt=''
                            />
                            </Link>
                    </Navbar.Brand>        
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="me-auto">
                        <Link to="/batismo" className="nav-link">    
                            Batismo
                        </Link>
                        <Link to="/catequese" className="nav-link">Catequese</Link>
                        <Nav.Link href="#">Crisma</Nav.Link>
                        <Nav.Link href="#">Casamento</Nav.Link>
                        <NavDropdown title="Pastorais" id="basic-nav-dropdown">
                          <NavDropdown.Item href="#action/3.1">Pastoral de Rua</NavDropdown.Item>
                          <NavDropdown.Item href="#action/3.2">
                            Pastoral do Dízimo
                          </NavDropdown.Item>
                          <NavDropdown.Item href="#action/3.3">Pastoral da Comunicação</NavDropdown.Item>
                          <NavDropdown.Divider />
                          <NavDropdown.Item href="#action/3.4">
                            Pastoral Familiar
                          </NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="#">Galeria de Fotos</Nav.Link>
                        <Link to="/login" className="nav-link">Uso interno</Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Container>
            </Navbar>
            );
}


export default Barra;