import Barra from '../../../elementos/barra';
import Footer from '../../../elementos/footer';
import Bebe from './bebe';
import Crianca from './crianca';
import Adulto from './adulto';

function SaibaMais(props) {

    return (
            <div>
            <Barra/>
            <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
            <main className="px-3">
            
            {props.idade === 'bebe' && <Bebe/>}
            {props.idade === 'crianca' && <Crianca/>}
            {props.idade === 'adulto' && <Adulto/>}
            </main>
            </div>
            <Footer/>
            </div>
            );
}


export default SaibaMais;