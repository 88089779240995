import Foto from '../../../img/header2.png';

function Header() {

    return (
        <div className="header">
            <img src={Foto} alt=""/>          
        </div>
    );
}


export default Header;