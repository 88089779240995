

function Bebe() {

    return (
            <div>
            <p className="roboto w-50 justificado">O Santo Batismo é o fundamento de toda a vida cristã, o pórtico da vida no Espírito
            e a porta que dá acesso aos outros sacramentos. Pelo Batismo somos libertos do pecado e regenerados
            como filhos de Deus. Tornamo-nos membros de Cristo e somos incorporados na Igreja
            e tornados participantes na sua missão.
            <br/><br/>
            Pais e padrinhos devem realizar o Curso de preparação para pais e padrinhos. 
            O curso pode ser realizado em qualquer paróquia, não mais que um ano antes do batizado.
            <br/><br/>
            Na Paróquia São Pedro do Mar o curso acontece na manhã do segundo ou terceiro sábado de mês 
            (consultar o calendário), com duração de 4 horas.
            <br/><br/>
            As inscrições para o Batismo ocorrem na secretaria da paróquia 
            na primeira e na segunda quarta-feira de cada mês, das 17:00h às 19:00h.
            </p>
            
            
            </div>
            );
}


export default Bebe;