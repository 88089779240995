import Barra from '../../elementos/barra';
import Footer from '../../elementos/footer';

function Catequese() {

    return (
            <div>
            <Barra/>
            Catequese
            <Footer/>
            </div>
            );
}


export default Catequese;