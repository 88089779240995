import {Link, useNavigate} from  'react-router-dom';
import {useState, useEffect} from 'react';
import axios from 'axios';

import HandleSession from '../../login/handleSession';

import Logo from '../../../img/logo-vertical-dourado.png';
import '../../login/login.css';

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

function TrocaSenha() {
    
    const [data,setData] = useState({
        novaSenha:'',
        confirme:'',
        email: HandleSession.getEmail()
    });
    const navigate = useNavigate();   

    const [erro, setErro] = useState("");
    
    const [isDisabled, setIsDisabled] = useState(true);
    
    
    
    const checa = () => {
        if (data.novaSenha.length < 8) {
            setIsDisabled(true);
            setErro('A senha deve ter mais que 8 caracteres.');
        } else if (data.novaSenha !== data.confirme) {
            setIsDisabled(true);
            setErro('As senhas não coincidem.');
        } else {
            setIsDisabled(false);
            setErro('');
        }
    };  

    useEffect(()=>{
        checa();
    },[data]);


    
    const handleChange = (e) => {
        setData({...data,[e.target.name]:e.target.value});
    };
    
    const submit = (e) => {

        e.preventDefault();
        let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/trocaSenha/trocaSenha.php";
        axios
                .post(url, data)

                .then(
                (res)=>{
                    console.log(res.data);
                    navigate("/restrito");
                });

    };
    
    const deslogar = () => {
        HandleSession.setName(null);
        HandleSession.setSenha(null);
    };
    
    
    return (
    <main>
    <center>
    <div className="coluna mb-5">
    <img src={Logo} alt="" className="my-5"/>
                    
        <Form>
                    
            <FloatingLabel 
                label="Nova Senha" 
                controlId="inputSenha" 
                className="mb-3"
            >
                <Form.Control 
                    type="password" 
                    placeholder="Nova Senha"
                    name="novaSenha"
                    onChange={handleChange}
                    value={data.novaSenha}
                    required
                />
            </FloatingLabel>
                    
            <FloatingLabel 
                label="Confirme" 
                controlId="inputConfirme" 
                className="mb-3"
            >
                <Form.Control 
                    type="password" 
                    placeholder="Confirme"
                    name="confirme"
                    onChange={handleChange}
                    value={data.confirme}
                    required
                />
                
                <span className="erro mb-5 py-0">{erro}</span>

            </FloatingLabel>
            
            <Button className="w-100 mb-1" disabled={isDisabled} onClick={submit}>Alterar</Button>
            
    
    
            
        </Form>
        
        <Stack>
            
            
            <Link to="/" onClick={deslogar}>Voltar para o Site</Link>
        </Stack>
    </div>
    </center>
    </main>
            );
}


export default TrocaSenha;