import Carrossel from './carrossel';
import {useEffect, useState} from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';

function Calendario() {
    
    const [registro, setRegistro] = useState([]);
    
    
    useEffect(()=>{
        async function getData (){
            let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/batismo/controles/get.php";
            //108.179.252.63 - host
            //172.20.10.2 - local            
            try{
                const result = await axios.get(url);
                setRegistro(result.data);
            } catch (error){
                alert(error);
            };
        };
        getData();
    },[]);    

    return (
            <div className="batismo_calendario">
            <Container>
            <h2 className="adlam fonte-azul-5 py-4">Calendário</h2>
            
            </Container>
            <Carrossel card={registro} />
            
            
            </div>
            );
}


export default Calendario;