import Barra from '../../elementos/barra';
import Footer from '../../elementos/footer';

import Header from './componentes/header';
import Horario from './componentes/horario';
import Encontros from './componentes/encontros';
import Dizimo from './componentes/dizimo';
import Avisos from './componentes/avisos';


function Home() {
    


    return (
            
    <div>
        <Barra/>
        <Header/>
        <Dizimo/>
        <Horario/>
        <Encontros/>
        <Avisos/>
        <Footer/>
    
    </div>
            
            );
}


export default Home;