/* 
 * Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
 * Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/javascript.js to edit this template
 */

var HandleSession = (function(){
    var full_name = "";
    var trocaSenha = "";
    var email = "";
    
    var getName = function(){
        return full_name;
    };
    
    var setName = function(name){
        full_name = name;
    };
    
    var getSenha = function(){
        return trocaSenha;
    };
    
    var setSenha = function(senha){
        trocaSenha = senha;
    };
    
    var getEmail = function(){
        return email;
    };
    
    var setEmail = function(mail){
        email = mail;
    };
    

    
    return {
        getName: getName,
        setName: setName,
        getSenha: getSenha,
        setSenha: setSenha,
        getEmail: getEmail,
        setEmail: setEmail
    };
})();

export default HandleSession;

