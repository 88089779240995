import React, {useState, useEffect} from 'react';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


import HorarioParoquia from '../../../img/horarioParoquia2.png';
import HorarioNotre from '../../../img/horarioNotre.png';
import HorarioMinimas from '../../../img/horarioMinimas.png';
import HorarioAparecida from '../../../img/horarioAparecida.png';
import HorarioFamilia from '../../../img/horarioFamilia.png';

import './home.css';
function Horario() {

    const [largura, setLargura] = useState(window.innerWidth);
    const minLargura = 574;
    
    useEffect(()=>{
        const resizeWindow = () => setLargura(window.innerWidth);
        window.addEventListener("resize", resizeWindow);
        return () => {
            window.removeEventListener("resize", resizeWindow);
        };
    }, []);
    
    if(largura > minLargura){
    return (
            <div className='mt-1'>

            
    <Tab.Container id="tab-horario" defaultActiveKey="paroquia">
      
            <center>
            <h1 className='adlam fonte-azul-5'>
                Horário das Missas
            </h1>
            </center>
        
          <Nav  className="nav-horario" justify transition={false}>
          
  
            <Nav.Item>
              <Nav.Link eventKey="paroquia">Paróquia São Pedro do Mar</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="notre">Colégio Notre Dame</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="minimas">Capela N. Sra. do Milagre</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="aparecida">Capela N. Sra. Aparecida</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="familia">Capela Sagrada Família</Nav.Link>
            </Nav.Item>            
          </Nav>
        
        
          <Tab.Content className='horario-conteudo'>
            <Tab.Pane eventKey="paroquia"><img src={HorarioParoquia} alt=''/></Tab.Pane>
            <Tab.Pane eventKey="notre"><img src={HorarioNotre} alt=''/></Tab.Pane>
            <Tab.Pane eventKey="minimas"><img src={HorarioMinimas} alt=''/></Tab.Pane>
            <Tab.Pane eventKey="aparecida"><img src={HorarioAparecida} alt=''/></Tab.Pane>
            <Tab.Pane eventKey="familia"><img src={HorarioFamilia} alt=''/></Tab.Pane>
          </Tab.Content>
        
      
    </Tab.Container>           
            
            </div>
            );
}else{
    return(
            <div className='horario-estreito'>
                <center>
                <h1 className='adlam pt-4'>
                    Horário das Missas
                </h1>
                </center>
                
                <Row className='bordaBranca'>
                <Col 
                    className='col-horario d-flex align-items-center' 
                    xs={3}>
                        <span className='span-horario ms-2'>
                            Paróquia São Pedro do Mar
                            </span>
                </Col>
                
                <Col className="px-0"
                    xs={9} 
                    >
                    <Image src={HorarioParoquia} alt='' className="w-100" fluid='true'/>
                </Col>
                </Row>

                <Row className='bordaBranca'>
                <Col className='col-horario d-flex align-items-center' xs={3}>
                <span className='span-horario ms-2'>Colégio Notre Dame</span></Col>
                <Col xs={9} className='px-0'><Image src={HorarioNotre} alt='' className="w-100" fluid='true'/></Col>
                </Row>
                
                <Row className='bordaBranca'>
                <Col className='col-horario d-flex align-items-center' xs={3}>
                <span className='span-horario ms-2'>Capela N. Sra. do Milagre</span></Col>
                <Col xs={9} className='px-0'><Image src={HorarioMinimas} alt='' className="w-100" fluid='true'/></Col>
                </Row>  
                
                <Row className='bordaBranca'>
                <Col className='col-horario d-flex align-items-center' xs={3}>
                <span className='span-horario ms-2'>Capela N. Sra. Aparecida</span></Col>
                <Col xs={9} className='px-0'><Image src={HorarioAparecida} alt='' className="w-100" fluid='true'/></Col>
                </Row>  
                
                <Row className='bordaBranca'>
                <Col className='col-horario d-flex align-items-center' xs={3}>
                <span className='span-horario ms-2'>Capela Sagrada Família</span></Col>
                <Col xs={9} className='px-0'><Image src={HorarioFamilia} alt='' className="w-100" fluid='true'/></Col>
                </Row>                 
            </div>
                );
}
}


export default Horario;