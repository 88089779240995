import axios from 'axios';
import {useState, useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import Container from  'react-bootstrap/Container';
import Form from  'react-bootstrap/Form';
import Button from  'react-bootstrap/Button';


function Lista() {
    
    const [registro, setRegistro] = useState([]);
    
    
    useEffect(()=>{
        async function getData (){
            let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/batismo/controles/get.php";
            try{
                const result = await axios.get(url);
                setRegistro(result.data);
                console.log(result.data);
            } catch (error){
                alert(error);
            };
        };
        getData();
    },[setRegistro]);
    
    const handleDelete = (item) =>{
        if(window.confirm("Tem certeza que deseja deletar esta linha?")){

            let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/batismo/controles/delete.php";
            axios
                    .post(url, item)
                    .then((res)=>{
                        window.location.reload(false);
               //console.log(res.data);
                        })
                    .catch((err)=>{console.log(err);});
        }
    };
    
    
    
    return (
            <Container className="mb-5">
            
    <h2 className="adlam fonte-azul-5">Próximas datas</h2>
    <div className="listaBatismos mx-auto mb-5">
    <Table className="mb-5">
    <thead>
    <tr>
    <th>Período</th>
    <th>Inscrições</th>
    <th>Encontros</th>
    <th>Batismo</th>
    <th>Autor</th>
    <th></th>
    </tr>
    </thead>
    <tbody>
            
    {Object.keys(registro).map((item, index) => {
                
        var meses = [
            "JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"
        ];
                
        var mes = registro[item].periodo.mes;
                
        return(
                <tr key={index}>
        <td>{meses[mes]}/{registro[item].periodo.ano}</td>
        <td>{registro[item].info.pre}<br/> {registro[item].info.inscricao}</td>
        <td>{registro[item].info.encontro}</td>
        <td>{registro[item].info.batismo}</td>
        <td>{registro[item].autor.nome}</td>                
                <td>
                <Form>
                <Form.Control type="hidden" value={registro[item].id}/>
                        <Button variant="danger" onClick={()=>handleDelete(registro[item])}>Excluir</Button>        
                </Form>
                </td>
                </tr>
                );
    })}
    </tbody>
    </Table>
    </div>
    </Container>
            );
    }


export default Lista;