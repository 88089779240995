import {Link} from 'react-router-dom';

function Selecao() {

    return (
            <div className="div_selecao d-flex justify-content-evenly">
            <div className="sel_item sel_baby d-flex align-items-center">
            <div className="mx-auto centrado">
            <h4 className="roboto centrado">Batismo de Bebês</h4>
            <p>(até 7 anos)</p>
            <Link className="btn btn-outline-secondary"
                to="/batismo/saibamais/bebe"
                >Saiba mais</Link>
            </div>
            </div>
            
            <div className="sel_item sel_adulto d-flex align-items-center">
            <div className="mx-auto centrado">
            <h4 className="roboto centrado">Batismo de Crianças</h4>
            <p>(acima de 7anos)</p>
            <Link className="btn btn-outline-secondary"
                to="/batismo/saibamais/crianca"
                >Saiba mais</Link>
            </div>            
            </div>

            <div className="sel_item sel_adulto d-flex align-items-center">
            <div className="mx-auto centrado">
            <h4 className="roboto centrado">Batismo de Adultos</h4>
            <p>(acima de 15 anos)</p>
            <Link className="btn btn-outline-secondary"
                to="/batismo/saibamais/adulto"
                >Saiba mais</Link>
            </div>            
            </div>
            </div>
            );
}


export default Selecao;