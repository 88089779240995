import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import Home       from './site/pagina/home/home';
import Batismo    from './site/pagina/batismo/batismo';
import Catequese  from './site/pagina/catequese/catequese';
import Login      from './site/pagina/login/login';
import Restrito   from './site/pagina/restrito/restrito';

import Bebe from './site/pagina/batismo/componentes/bebe';
import Crianca from './site/pagina/batismo/componentes/crianca';
import Adulto from './site/pagina/batismo/componentes/adulto';
import SaibaMais from './site/pagina/batismo/componentes/saibaMais';



import Usuarios from './site/pagina/restrito/usuarios/usuarios';
import TrocaSenha from './site/pagina/restrito/trocaSenha/trocaSenha';



function App() {
      return (
    <BrowserRouter>
    <Routes>
    <Route path="/">
        <Route index element={<Home/>}/>
        <Route path="/batismo" >
            <Route index element={<Batismo/>} />
            <Route path="/batismo/saibamais/bebe" element={<SaibaMais idade="bebe"/>}/>
            <Route path="/batismo/saibamais/crianca" element={<SaibaMais idade="crianca"/>}/>
            <Route path="/batismo/saibamais/adulto" element={<SaibaMais idade="adulto"/>}/>
        </Route>
        <Route path="/catequese" element={<Catequese/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="restrito/*" element={<Restrito />} />
        <Route path="/trocaSenha" element={<TrocaSenha/>}/>

    </Route>
    </Routes>
    

    </BrowserRouter>

    


  );
}

export default App;
//<!--            <Routes>
//            <Route path="/restrito">
  //              <Route index element={//<Restrito/>}/>
    //            <Route path="/restrito/usuarios" element={//<Usuarios/>}/>
      //      </Route>
        //    </Routes>-->