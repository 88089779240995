import {Link, useNavigate} from  'react-router-dom';
import {useState} from 'react';
import axios from 'axios';

import HandleSession from './handleSession';

import Logo from '../../img/logo-vertical-dourado.png';
import './login.css';

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';





function Login() {
    
    const [data,setData] = useState({
        senha:'',
        email:''
    });
    
    const handleChange = (e) => {
        setData({...data,[e.target.name]:e.target.value});
    };
    
    const navigate = useNavigate();
    
    const login = (e) => {
        e.preventDefault();
        let url = "http://localhost:8888/saopedro/src/site/pagina/login/componentes/login.php";
        
        axios
                .post(url, data)
        
                .then(
                    (res)=>{
                        if(res.data.success)
                            {
                                HandleSession.setName(res.data.usuario.nome);
                                HandleSession.setSenha(res.data.usuario.trocaSenha);
                                HandleSession.setEmail(res.data.usuario.email);
                                sessionStorage.setItem("idUsuario", res.data.id);
                                sessionStorage.setItem("email", res.data.usuario.email);
                                sessionStorage.setItem("admin", res.data.usuario.admin);
                                res.data.usuario.trocaSenha ? navigate("/trocaSenha") : navigate("/restrito");
                            }
                        else
                            {
                                alert(res.data.message);
                            }
                    }
                        )
                
                .catch(
                    (err)=>{
                        alert(err);
                    }
                        );        
    };

    return (
            
    <main>
    <center>
    <div className="coluna mb-5">
    <img src={Logo} alt="" className="my-5"/>
                    
        <Form>
                    
            <FloatingLabel 
                label="E-mail" 
                controlId="inputLogin" 
                className="mb-3"
            >
                <Form.Control 
                    type="email" 
                    placeholder="E-mail"
                    name="email"
                    onChange={handleChange}
                    value={data.email}
                    required
                />
            </FloatingLabel>
                    
            <FloatingLabel 
                label="Senha" 
                controlId="inputSenha" 
                className="mb-3"
            >
                <Form.Control 
                    type="password" 
                    placeholder="Senha"
                    name="senha"
                    onChange={handleChange}
                    value={data.senha}
                    required
                />
            </FloatingLabel>
            
            <Button className="w-100 mb-5" onClick={login}>Entrar</Button>
            
        </Form>
        
        <Stack>
            
            <Link to="/recuperaSenha">Esqueci minha Senha</Link>
            
            <Link to="/">Voltar para o Site</Link>
        </Stack>
    </div>
    </center>
    </main>
            );
}


export default Login;