import './home.css';

import {useState, useEffect} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import axios from 'axios';



function Avisos() {
    
    
    const [registro, setRegistro] = useState([]);

    useEffect(()=>{
        async function getData (){
            //let url = "ttp://localhost:8888/saopedro/src/site/pagina/restrito/avisos/controles/get.php?tipo=aviso";
            let URL = "http://dev.saopedrodomar.com/src/site/pagina/restrito/avisos/controles/get.php?tipo=encontro";
            
            
            //108.179.252.60 - host
            //172.20.10.2 - local
            try{
                const result = await axios.get(URL);
                setRegistro(result.data);
            } 
            catch (error){
                console.log(error);
            };
        };
        getData();
        
    },[]);   

    return (
            <div id="div-avisos">
     <div className="aviso-lista">
        <center>
            <h2 className="adlam fonte-azul-5">Avisos</h2> 
        </center>
        
            <Carousel fade className="encontros-carrossel mx-auto">
                {registro.map((item, i)=>{
                    return(
                            <Carousel.Item key={i}>
                <img src={require(`../../restrito/avisos/img/${item.arquivo}`)} alt=""/>
                </Carousel.Item>            
                );
                })}
            

            </Carousel>
    </div>
    </div>
    );
}


export default Avisos;