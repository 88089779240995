import './avisos.css';
import axios from 'axios';
import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import CloseButton from 'react-bootstrap/CloseButton';
import Stack from 'react-bootstrap/Stack';




function Lista() {
    
    const [registro, setRegistro] = useState([]);
    let {state} = useLocation();
    
    useEffect(()=>{
        async function getData (){
            let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/avisos/controles/get.php?tipo=" + state.tipo;
            try{
                const result = await axios.get(url);
                setRegistro(result.data);
                console.log(result);
            } 
            catch (error){
                if(error.res){
                    alert("erro res");
                    alert(error.res.status);
                    alert(error.res.headers);
                }else if (error.request){
                    alert("error.request");
                }else{
                    alert("error.message");
                }
                alert("error.config");
            };
        };
        getData();
        
    },[state.tipo]);
    
    const handleDelete = (item) => {
        if(window.confirm("Tem certeza que deseja deletar esta arte? Esta ação não poderá ser desfeita."))
        {
            let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/avisos/controles/delete.php";
            

            axios
                    .post(url, item)
                    .then(()=>{
                        window.location.reload(false);
                        
                        })
                    .catch((err)=>{console.log(err);});
        }
    }; 
    
    return (
            <div className="listaAvisos mt-5 mx-auto d-flex  ">
            {registro.map((item, index) => (
                    <Stack key={index} className="flex-nowrap">
                    <center>
                        <img  src={require(`./img/${item.arquivo}`)} alt={item.arquivo} />
                    </center>
                    <center>
                        <CloseButton onClick={()=>handleDelete({item})} /><br/>
                    <span className="roboto post_detail">Postado por: {item.nome}<br/>Em {item.data}</span>
                    </center>

                    </Stack>
                    ))}
            </div>
            );
}


export default Lista;