import {useState} from 'react';
import Arte from '../../../img/dizimo.jpg';
import Image from 'react-bootstrap/Image';
import CloseButton from 'react-bootstrap/CloseButton';
import './home.css';


function Dizimo() {
    
    const [mostra, setMostra] = useState(true);

    const toogleDizimo = () => {
        setMostra(false);
    };

    if(mostra){
    return (
            <div id="div-dizimo" >
                <div className="d-flex">
                    <CloseButton className="ms-auto" onClick={toogleDizimo}/>
                </div>
                <Image src={Arte} alt="" fluid className="w-100"/>
            </div>
            );
    }
}


export default Dizimo;