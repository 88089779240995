import './elementos.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Pascom from '../img/pascom.png';



function Footer() {
    

    return (
            <div className="elemento-footer py-5 align-self-end">
            <Container>
            <Row>
                
                
            <Col lg={4} md={12} className="coluna-footer">
                <h4>Sobre nós</h4>
                <p>Localizada no Recreio dos Bandeirantes, a Paróquia foi fundada em 18 de Fevereiro de 1987.</p>
                <p>Tem como missão levar as pessoas a encontrarem-se com Cristo e amá-lo</p>
                <p>Pároco: Monsenhor Luiz Artur</p>
            </Col>
            <Col lg={4} md={12} className="coluna-footer">
                <h4>Horários da Secretaria</h4>
                <p><b>Segunda a Sábado</b><br/>08:00h às 12:00h</p>
                <p><b>Segunda a Sexta</b><br/>14:00h às 18:00h</p>
            </Col>
            <Col lg={4} md={12} className="coluna-footer">
                <h4>Fale Conosco</h4>
                <p>Rua Gilberto Freire, 100<br/>Recreio dos Bandeirantes <br/> Rio de Janeiro-RJ<br/>CEP: 22790-690</p>
                <p><a href="mailto:saopedrodomar@hotmail.com" className="limpo">saopedrodomar@hotmail.com</a></p>
                <p><a href="tel:+552124902287" className="limpo">(21) 2490-2287</a></p>
                <p><a href="tel:+5521993872280" className="limpo">(21) 99387-2280</a></p>
                
            </Col>

            </Row>
            <Row className="mt-5">
            <center>
            <img src={Pascom} alt='' width={150}/><br/>
            </center>
            </Row>
            </Container>
            </div>
            );
}


export default Footer;