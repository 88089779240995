import Barra from '../componentes/barra';
import Formulario from './formulario';
import Lista from './lista';

import axios from 'axios';

import {useState, useEffect} from 'react';

function Usuarios() {
    

    return (
            <div>
            <Formulario/>
            <Lista/>
            </div>
            );
}


export default Usuarios;