import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';

import axios from 'axios';

import {useState} from 'react';

import './usuarios.css';

function Formulario() {
    
    const reset = {
        nome:'',
        email:'',
        admin:false
    }; 
    
    const [data, setData] = useState(reset);
    

    const handleChange = (e) => {
        setData({...data,[e.target.name]:e.target.value});
    };
    
    const handleCheck = (e) =>{
        setData({ ...data, [e.target.name]:e.target.checked});
    };
        

    const submit = (e)=>{
        e.preventDefault();
        let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/usuarios/controles/add.php";
        
        axios
                .post(url, data)
                .then(
                    ()=>{
                        setData(reset);
                        alert("Salvo com sucesso!");
                        window.location.reload(false);
                    }
                )
                .catch(
                    (erro)=>{
                        if(erro.response.data.status === 401){
                            alert(erro.response.data.message);
                        }else{
                            alert(erro.message);
                        }
            });
    };
    


    return (
            <Form className="form-usuario mx-auto">
            <h1 className="adlam fonte-azul-5 mb-3">Cadastrar Usuário</h1>
            
            <FloatingLabel label="Nome" controlId="nome" className="mb-3">
            <Form.Control type="text" placeholder="Nome" name="nome" onChange={handleChange} value={data.nome}/>
            </FloatingLabel>
            
            <FloatingLabel label="E-mail" controlId="email" className="mb-3">
            <Form.Control type="text" placeholder="E-mail" name="email" onChange={handleChange} value={data.email}/>
            </FloatingLabel>    
            
            <Form.Check 
                type="switch"
                id="admin"
                name="admin"
                label="Administrador" 
                className="mb-3"
                value={data.admin}
                onChange={handleCheck}
                />
            
            <Button className="w-100 mb-5" onClick={submit}>Cadastrar</Button>
            </Form>
            );
}


export default Formulario;