import {useEffect, useState} from 'react';
import Batismo_h from '../../../img/batismo_h.png';
import Batismo_v from '../../../img/batismo_v.png';


function Capa() {
    const [largura, setLargura] = useState(window.innerWidth);
    const [altura, setAltura] = useState(window.innerHeight);
    
    useEffect(()=>{
        const resizeWindow = () => setLargura(window.innerWidth);
        window.addEventListener("resize", resizeWindow);
        return () => {
            window.removeEventListener("resize", resizeWindow);
        };
    }, []);
            
    if(largura > altura){
                
        return(
        
                <div className="capa-batismo">
                    <img src={Batismo_h} alt="" className="w-100"/>
                </div>
        );
    }
    else{  
        return(
                <div className="capa-batismo">
                    <img src={Batismo_v} alt="" className="w-100"/>
                </div>
                );
    }   

}


export default Capa;