import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Lista from './lista';

function Carrossel({ card }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 751 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile_wide: {
      breakpoint: { max: 750, min: 376 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 375, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <Carousel 
        className="batismo_carrossel"
      swipeable={true}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={true}
      rewind={true}
      autoPlaySpeed={7000}
      keyBoardControl={true}
      customTransition="transform 1000ms ease-in-out"
      transitionDuration={1000}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {card.map((item, index) => (
        <Lista key={index} lista={[item]} />
      ))}
    </Carousel>
  );
}

export default Carrossel;
