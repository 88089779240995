import Barra from '../../elementos/barra';
import Footer from '../../elementos/footer';
import './componentes/batismo.css';
import Capa from './componentes/capa';
import Selecao from './componentes/selecao';
import Calendario from './componentes/calendario';

function Batismo() {

    

    return (
            <div>
    <Barra/>

    <Capa/>
    <Selecao/>
    <Calendario/>
    <Footer/>
            </div>
            );
}


export default Batismo;