import axios from 'axios';
import {useState, useEffect} from 'react';
import Table from 'react-bootstrap/Table';

import './usuarios.css';
import Excluir from '../../../img/excluir.png';

function Lista() {
    const [registro, setRegistro] = useState([]);
    
    
    useEffect(()=>{
        async function getData (){
            let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/usuarios/controles/get.php";
            try{
                const result = await axios.get(url);
                setRegistro(result.data);
            } catch (error){
                alert(error);
            };
        };
        getData();
    },[]);
    
    function handleDelete(usuario){
        if(window.confirm("Tem certeza que deseja deletar este usuário?")){

            let url = "http://localhost:8888/saopedro/src/site/pagina/restrito/usuarios/controles/delete.php";
            axios
                    .post(url, usuario)
                    .then(()=>{
                        window.location.reload(false);
                        })
                    .catch((err)=>{console.log(err);});
        }
    }
    
    return (
        <div className="listaUsuarios mx-auto mb-5">
            <h2 className="adlam fonte-azul-5">Usuários Cadastrados</h2>
            <Table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {registro.map((usuario, index) => (
                        <tr key={index}>
                            <td>{usuario.nome}</td>
                            <td>{usuario.email}</td>
                            <td>{usuario.admin ? "Administrador" : null}</td>
                            <td>
                                <img 
                                    src={Excluir} 
                                    alt="" 
                                    className="btn btn-excluir" 
                                    onClick={()=>handleDelete(usuario)}
                                    />
                                    
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );    
}


export default Lista;
    
